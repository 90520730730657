@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
}


        body, html {
            margin: 0;
            padding: 0;
            height: 100%;
			width: 100%;
            font-family: Arial, sans-serif;
        }

		.dt_wrapper {
			min-height: 100vh;
			background-image: 
				url('./helpmr-outline-watermark.png'), 
				linear-gradient(114deg, rgb(0, 139, 184), rgb(63, 196, 184));
			background-position: center center, center center;
			background-size: 800px, cover;
			background-repeat: no-repeat;
			display: flex;
			flex-direction: column; /* A tartalom továbbra is oszlopban van */
			align-items: center; /* Horizontálisan középre igazít */
			padding-top: 40px; /* Adjunk némi felső margót, ha szükséges */
			width: 100%; /* A wrapper szélességét 100%-ra állítjuk */
		}

		.dt_logo {
			height: 60px;
			top: 20px;
			margin-bottom: 20px;
		}
		.dt_gep {
			
			top: 20px;
			width: auto;
			/* width: 662px;  */
			max-width: 100%; /* A kép méretének maximális szélessége legyen 100% */
			height: auto; /* Automatikus magasság, hogy megtartsa az arányait */
			
    
			/* margin: 20px 0;  Margin hozzáadva, hogy elkerüljük a képpel való ütközést */
    
    
		}
		

        .dt_h1 {
            text-align: center;
			font-family: Montserrat, sans-serif;
			letter-spacing: -0.72px;
            font-size: 36px;
			line-height: 44px;
			font-weight: 450;
            color: white;
            margin-bottom: 20px;
        }
		
		.dt_p {
            text-align: center;
			font-family: Montserrat, sans-serif;
			letter-spacing: -0.72px;
            font-size: 22px;
			line-height: 25px;
            color: white;
            margin-bottom: 21px;
			font-weight: 450;
        }

        .dt_container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            
            max-width: 1200px;
            width: 100%;
			margin-bottom: 20px;
        }

        .dt_box {
            /* background-color: rgba(255, 255, 255, 0.8);  Átlátszó fehér háttér */
			background-color: rgb(232, 108, 4);
            color: white;
            /* padding: 20px; */
            margin: 10px;
            flex-basis: 30%;
            box-sizing: border-box;
            text-align: center;
			
			font-family: Montserrat, sans-serif;
			letter-spacing: -0.72px;
            font-size: 22px;
			line-height: 28px;
			font-weight: 450;
            
			margin-top: 1.5rem; 
			padding: 1rem;
			
            border-radius: 0px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            transition: transform 0.3s;
        }

        .dt_box:hover {
            transform: scale(1.05);
        }
		.dt_loader {
			display: flex;
			flex-direction: column;
			align-items: center; /* Horizontálisan középre */
            margin: 10px;
            flex-basis: 30%;
            box-sizing: border-box;
            text-align: center;
			line-height: 28px;
			margin-top: 1.5rem; 
			padding: 20px;
			
            
            
        }

        @media (max-width: 768px) {
            .box {
                flex-basis: 100%;
                font-size: 1.2em;
            }
        }
		
		.dt_spinner_loader {
			width: 100px;
			padding: 8px;
			aspect-ratio: 1;
			border-radius: 50%;
			background: #ffffff;
				--_m: 
				conic-gradient(#0000 10%,#000),
				linear-gradient(#000 0 0) content-box;
				-webkit-mask: var(--_m);
				mask: var(--_m);
				-webkit-mask-composite: source-out;
				mask-composite: subtract;
				animation: l3 1s infinite linear;
		}
		@keyframes l3 {to{transform: rotate(1turn)}}